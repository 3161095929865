import { createSlice } from "@reduxjs/toolkit";

const key = createSlice({
  name: "key",
  initialState: {
    isChecked: false,
    key: "",
  },
  reducers: {
    success: (state, action) => {
      state.isChecked = true;
      state.key = action.payload;
    },
    reset: (state, action) => {
      state.isChecked = false;
      state.key = "";
    },
  },
});

const { reducer, actions } = key;
export const { success, reset } = actions;
export default reducer;
