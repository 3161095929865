import { createSlice } from "@reduxjs/toolkit";


const sidebarShow = createSlice({
    name : "responsive",
    initialState : "responsive",
    reducers : {
        set : (state, action) => {
            state = action.payload  
            return state;       
        }, 

       
    }
})

const {reducer , actions} = sidebarShow;
export const {set} = actions;
export default reducer;