import { createSlice } from "@reduxjs/toolkit";


const employee = createSlice({
    name : "employee",
    initialState : [],
    reducers : {
        login : (state, action) => {
           state.push(action.payload)      
        }, 
        empArr : (state, action) => {
            state.push(action.payload) 
        },

       
    }
})

const {reducer , actions} = employee;
export const {login, empArr} = actions;
export default reducer;